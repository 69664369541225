<template>
  <div>
    <div v-if="!bottomProduct" class="default-content">
      <p style="font-size: xx-large;">暂无数据</p>
      <p style="font-size: large;">请点击左侧生成订单数据</p>
    </div>

    <div v-else>
      <Row class="p-b-5">
          <i-col span="24" class="text-14">
              <span>采购媒体： {{bottomProduct.categoryName}}-{{bottomProduct.productName}} {{formatSchedule(bottomProduct.startDate,bottomProduct.endDate)}}</span>
          </i-col>
      </Row>
      <hr class="workplatform-hr"/>
      <component :is="editComponent" :params="bottomProduct" @on-update-order="updatedPurchaseItems" @on-update-map="updateMapStations"></component>
    </div>
  </div>
</template>

<script>
import { sysMixins } from '@/assets/mixins/sys'
import { GetCurrentSchedule } from '@/utils/dateFormat'
import { toMoney } from '@/utils/wnumb_own'

export default {
  mixins: [sysMixins],
  components: {
    eMetroComponent: () => import('@/components/order/edit/Metro'),
    eSubwayComponent: () => import('@/components/order/edit/Subway'),
    eStairSignComponent: () => import('@/components/order/edit/StairSign'), // 梯牌
    eMetroSeriesComponent: () => import('@/components/order/edit/MetroSeries'), // 连封灯箱
    eElecChooseComponent: () => import('@/components/order/edit/ElecChoose'), // 点播屏
    eThemeComponent: () => import('@/components/order/edit/Theme'), // 主题媒介
    eMetroVoiceComponent: () => import('@/components/order/edit/Voice'), // 语音
    eGroupComponent: () => import('@/components/order/edit/Group'), // 套装
    ePISComponent: () => import('@/components/order/edit/PIS') // PIS屏
  },
  data () {
    return {
      editComponent: ''
    }
  },
  mounted () {
    this.handleProductChosen()
  },
  methods: {
    formatSchedule (start, end) {
      return GetCurrentSchedule(start, end)
    },
    formatMoney (number) {
      return toMoney(number)
    },
    handleProductChosen () { // 显示编辑界面
      if (!this.bottomProduct) {
        this.setBottomHeight('5px')
        return
      } else {
        this.setBottomHeight('300px')
      }

      const componentMap = [
        { productType: 1, name: 'eMetro' }, // 暂时兼容有BRT
        { productType: 2, name: 'eTheme' },
        { productType: 3, name: 'eSubway' },
        { productType: 4, name: 'eStairSign' }, // 梯牌
        { productType: 5, name: 'eSubway' }, // 暂时兼容候车亭灯箱
        { productType: 6, name: 'eGroup' }, // 套装
        { productType: 7, name: 'ePIS' }, // 地铁PIS屏
        { productType: 9, name: 'eMetroVoice' }, // 地铁语音
        { productType: 14, name: 'eElecChoose' }, // 点播屏
        { productType: 16, name: 'eMetroSeries' }, // 连封灯箱套装
        { productType: 18, name: 'eMetro' }, // 北京电子屏
        { productType: 19, name: 'eTheme' } // 北京电子屏主题媒介组合
      ]

      const component = componentMap.find(x => x.productType === this.bottomProduct.productType) || { name: '' }
      this.editComponent = component.name + 'Component'
    },
    updatedPurchaseItems (categoryId) {
      // 如果左侧等于底部的类型，那么刷新左侧
      if (this.categoryId === categoryId) {
        this.$store.commit('set_order_update_left', new Date())
      }

      this.$store.commit('set_order_update_purchase', new Date())
    },
    updateMapStations (stations) {
      // 此处需要根据地图的不同显示方式，才执行更新操作
      if (this.mapType === 'purchase') {
        this.$store.commit('set_order_update_map', new Date())
        this.$store.commit('set_order_stations', stations)

        // 更新显示线路
        const showAssetIds = []
        stations.forEach(element => {
          if (!showAssetIds.includes(element.rimAssetId)) {
            showAssetIds.push(element.rimAssetId)
          }
        })

        this.$store.commit('set_order_show_assets', showAssetIds)
      } else if (this.mapType === 'full' && stations.length > 0) {
        // 左侧重新加载数据
        this.$store.commit('set_order_update_left', new Date())
      }
    }
  },
  computed: {
    bottomProduct () {
      return this.$store.state.order.bottomProduct
    },
    mapType () {
      return this.$store.state.order.mapType
    },
    categoryId () {
      return this.$store.state.order.categoryId
    }
  },
  watch: {
    bottomProduct (val) {
      this.handleProductChosen()
    }
  }
}
</script>
